<template>
  <div v-loading="loading" class="verification-numbers">
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
      class="header"
    >
      <div>
        <el-input
          v-model="search"
          placeholder="Search"
          clearable
        />
      </div>
      <div>
        <el-button @click="fetchNumbers">
          Refresh
        </el-button>
        <el-button
          type="primary"
          @click="addPhoneNumberHandler"
        >
          Add Phone Number
        </el-button>
      </div>
    </el-row>
    <el-row>
      <el-table
        :data="filteredNumbers"
      >
        <el-table-column
          label="Phone Number"
          prop="number"
          sortable
        />
        <el-table-column
          label="Last Used"
          prop="last_succeeded"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.last_succeeded && format(parseISO(scope.row.last_succeeded), 'yyyy-MM-dd h:mm a') }}
          </template>
        </el-table-column>
        <el-table-column
          label="Status"
          prop="status"
          class-name="capitalize"
          sortable
        />
        <el-table-column
          label="Verification Method"
          prop="preferred_method"
          :formatter="formatPreferredMethod"
          sortable
        />
        <el-table-column
          label="Notes"
          prop="notes"
          min-width="200"
          class-name="notes"
        />
        <el-table-column type="expand">
          <template slot-scope="props">
            <h4>Notes</h4>
            <p>{{ props.row.notes }}</p>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot-scope="scope">
            <el-dropdown trigger="click" @command="handleCommand">
              <span>
                <i class="el-icon-more" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ action: 'edit', obj: scope.row }">
                  Edit
                </el-dropdown-item>
                <el-dropdown-item :command="{ action: 'delete', obj: scope.row }">
                  Delete
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <verification-number-form
      :show="showNumberForm"
      :number="selectedNumber"
      @add-number="addNumber"
      @edit-number="editNumber"
      @close="showNumberForm = false"
    />

    <verification-number-delete-dialog
      :show="showDeleteConfirmation"
      :number="selectedNumber"
      @submit="deleteNumber"
      @close="showDeleteConfirmation = false"
    />
  </div>
</template>

<script>
import CraigslistAPI from '@/services/api/craigslist'
import VerificationNumberFormDialog from './_components/VerificationNumberFormDialog'
import VerificationNumberDeleteDialog from './_components/VerificationNumberDeleteDialog'
import { format, parseISO } from 'date-fns'

export default {
  name: 'VerificationNumbers',
  components: {
    'verification-number-form': VerificationNumberFormDialog,
    'verification-number-delete-dialog': VerificationNumberDeleteDialog
  },
  data () {
    return {
      loading: false,
      search: '',
      numbers: [],
      selectedNumber: null,
      showNumberForm: false,
      showDeleteConfirmation: false
    }
  },
  computed: {
    filteredNumbers () {
      const search = this.search.toLowerCase()
      return this.numbers.filter(number =>
        number.number.includes(search) ||
        number.notes.toLowerCase().includes(search)
      )
    }
  },
  created () {
    this.format = format
    this.parseISO = parseISO
    this.fetchNumbers()
  },
  methods: {
    /**
     * Fetch phone verification numbers from the API.
     */
    async fetchNumbers () {
      try {
        this.loading = true
        this.numbers = await CraigslistAPI.verification_numbers.list()
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Formats the preferred method values
     */
    formatPreferredMethod (row, column, cellValue) {
      switch (cellValue) {
        case 'sms':
          return 'SMS'
        case 'voice':
          return 'Voice'
        default:
          return cellValue
      }
    },
    /**
     * onClick handler for Add Phone Number button
     */
    addPhoneNumberHandler () {
      this.selectedNumber = null
      this.showNumberForm = true
    },
    /**
     * Submits request to API to add a new phone number.
     *
     * @param {Object} formData
     */
    async addNumber (formData) {
      try {
        this.loading = true
        await CraigslistAPI.verification_numbers.create(formData)
        this.showNumberForm = false
        this.fetchNumbers()
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
    },
    /**
     * onClick handler for action menu dropdown item select.
     *
     * @param {Object} command
     * @param {String} command.action - action to execute
     * @param {Object} command.obj - target number
     */
    handleCommand (command) {
      this.selectedNumber = command.obj
      switch (command.action) {
        case 'edit':
          this.showNumberForm = true
          break
        case 'delete':
          this.showDeleteConfirmation = true
          break
      }
    },
    /**
     * Submits request to API to update an existing phone number.
     *
     * @param {Object} formData
     */
    async editNumber (formData) {
      try {
        this.loading = true
        await CraigslistAPI.verification_numbers.partialUpdate(formData.id, formData)
        this.showNumberForm = false
        this.fetchNumbers()
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
    },
    /**
     * Submits request to API to delete an existing phone number.
     *
     * @param {Object} formData
     */
    async deleteNumber (formData) {
      try {
        this.loading = true
        await CraigslistAPI.verification_numbers.delete(formData.id)
        this.showDeleteConfirmation = false
        this.fetchNumbers()
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.verification-numbers {
  .header {
    margin: 1em 0;
  }
  ::v-deep .notes .cell {
    white-space: nowrap;
  }
  .el-icon-more {
    transform: rotate(90deg);
    padding: 5px;

    &:hover {
      cursor: pointer;
    }
  }
  ::v-deep .capitalize {
    text-transform: capitalize;
  }
}
</style>
