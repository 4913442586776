<template>
  <el-dialog
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    title="Delete Phone Number"
    width="460px"
    @close="$emit('close')"
  >
    <div>
      Are you sure you want to delete the
      phone number <b>{{ number && number.number }}</b>?
      <p><b>This action cannot be undone.</b></p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">
        Cancel
      </el-button>
      <el-button type="danger" @click="submit">
        Delete
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'VerificationNumberDeleteDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    number: {
      type: Object,
      default: null
    }
  },
  methods: {
    /**
     * onClick handler for the delete button is clicked.
     * Emits a submit event along with the number to
     * be deleted.
     */
    submit () {
      this.$emit('submit', this.number)
    }
  }
}
</script>
