<template>
  <el-dialog
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :title="isEdit ? 'Edit Phone Number' : 'Add Phone Number'"
    width="450px"
    @close="cancel"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="140px"
    >
      <el-form-item
        label="Phone Number"
        prop="number"
      >
        <el-input
          v-model="formData.number"
        />
      </el-form-item>
      <el-form-item
        label="Notes"
        prop="notes"
      >
        <el-input
          v-model="formData.notes"
          type="textarea"
        />
      </el-form-item>
      <el-form-item
        label="Verification Method"
        prop="preferred_method"
      >
        <el-radio-group v-model="formData.preferred_method">
          <el-radio label="sms">
            SMS
          </el-radio>
          <el-radio label="voice">
            Voice
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">
        Cancel
      </el-button>
      <el-button type="primary" @click="submit">
        {{ isEdit ? 'Save' : 'Add Phone Number' }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'VerificationNumberFormDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    number: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      formData: null,
      rules: {
        number: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 31, message: 'Phone number cannot be more than 31 characters', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    isEdit () {
      return this.number !== null
    }
  },
  watch: {
    number: {
      handler () {
        this.formData = this.getNumberObject()
      },
      immediate: true
    }
  },
  methods: {
    /**
     * onClick handler for submit button.
     * Emits 'submit' event if form data is valid.
     */
    submit () {
      this.$refs['form'].validate(valid => {
        if (!valid) {
          return
        }
        this.$emit(this.isEdit ? 'edit-number' : 'add-number', this.formData)
        this.reset()
      })
    },
    /**
     * onClick handler for cancel button.
     * Resets form fields and emits 'cancel' event.
     */
    cancel () {
      this.$emit('close')
      this.reset()
    },
    /**
     * Reset form to default values.
     */
    reset () {
      this.$refs['form'].clearValidate()
      this.formData = this.getNumberObject()
    },
    /**
     * If editing an existing number, this will return
     * a copy of that number.
     * If not, it will generate an object of default values.
     *
     * @returns {Object}
     */
    getNumberObject () {
      if (this.isEdit) {
        return JSON.parse(JSON.stringify(this.number))
      }
      return {
        number: '',
        preferred_method: 'sms',
        notes: ''
      }
    }
  }
}
</script>
